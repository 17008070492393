var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"7"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-center primary--text font-weight-bold"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Add Vueservations widget to public facing site to allow online reservations. ")]),_c('p',{staticClass:"text-caption"},[_vm._v("(demo disabled)")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"7"}},[_c('v-card',{staticClass:"d-flex flex-column justify-start"},[_c('v-card-text',{staticClass:"pt-6"},[_c('v-text-field',{staticClass:"flex-grow-0 flex-shrink-1",attrs:{"solo":"","rounded":"","clearable":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search","color":"primary"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}}),_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.orderBy(_vm.categoriesAvailable, 'name'),"item-text":"name","item-value":"id","label":"Category","attach":"#mm","multiple":"","menu-props":{
            absolute: true,
            bottom: true,
            'min-width': '100%'
          },"chips":"","full-width":"","deletable-chips":"","no-hint":"","no-title":"","clearable":"","color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(
                  _vm.filterCategory.includes(item.id)
                    ? 'mdi-checkbox-marked'
                    : 'mdi-checkbox-blank-outline'
                )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s('mdi-checkbox-blank-circle')}})],1)]}},{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":item.color,"close":""},on:{"click:close":function($event){_vm.filterCategory = _vm.filterCategory.filter(function (i) { return i !== item.id; })}}},[_c('span',[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.filterCategory),callback:function ($$v) {_vm.filterCategory=$$v},expression:"filterCategory"}}),_c('v-sheet',{attrs:{"id":"mm","height":"10","width":"100%"}})],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"11","lg":"8"}},[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}):_c('ciList',{attrs:{"items":_vm.itemList,"categories":_vm.categories,"categoriesById":_vm.categoriesById}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }